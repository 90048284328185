<script setup>
import { onMounted, ref } from "vue";

defineProps({
    modelValue: {
        type: [String, Number],
        required: true,
    },
    prefix: {
        type: String,
        default: null,
    },
});

defineEmits(["update:modelValue"]);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <div class="border-0 p-0">
        <div class="relative rounded-md shadow-sm">
            <div
                v-show="prefix"
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
                <span class="text-gray-500 sm:text-sm">{{ prefix }}</span>
            </div>
            <input
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                :class="{ 'pl-7': prefix }"
                v-bind="$attrs"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                ref="input"
            />
        </div>
    </div>
</template>
